<template>
  <div>
    <b-modal 
    hide-footer no-enforce-focus 
    :no-close-on-backdrop="true"
    id="orderProcessing" 
    :centered="true"
    size="lg"
    @show="getOrderProcessing()"
    @close="getClear()">
        <div class="parent_block">
            <b-row class="block1">
                <b-col class="12">
                    <b-table
                    striped
                    small
                    id="myTables"
                    bordered
                    selectable
                    selected-variant="primary"
                    select-mode="single"
                    responsive="sm"
                    :items="orderProcessingArray"
                    :fields="fields"
                    head-row-variant="secondary"
                    :tbody-tr-class="rowClass"
                    @row-clicked="clicked"
                    >
                    <template #cell(checkbox)="row">
                        <b-form-checkbox
                            v-model="row.detailsShowing"
                            plain
                            class="vs-checkbox-con"
                            @change="row.toggleDetails"
                        >
                        </b-form-checkbox>
                    </template>
                    <template #cell(to_addresses)="data">
                            <span v-for="addresses in data.item.to_addresses" :key="addresses.id">
                                {{addresses.name}}
                            </span>
                    </template>
                    </b-table>
                </b-col>
            </b-row>
        </div>
        <hr>
        <b-row>
            <b-col class="d-flex flex-row bd-highlight">
                <label class="inputform">Время подачи(мин): *</label>
                <b-form-input v-model="filing_time" type="number" class="w-25"></b-form-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" class="d-flex justify-content-between">
                <b-button
                    :disabled="submitButtonDisabled"
                    @click="getFilingTimeEdit()"
                    class='mt-2 col-md-5'
                    variant="primary">
                        Ок
                </b-button>
                <b-button class="mt-2 col-md-5" variant="secondary" @click="getClear()">
                    Отмена
                </b-button>
            </b-col>
        </b-row>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
export default {
    components: {
        ToastificationContent
    },
    props: ['orderProcessing'],
    data() {
        return {
            submitButtonDisabled: false,
            orderProcessingArray: [],
            filing_time: null,
            order_id: null,
            fields: [
                { key: 'id', label: 'ID', sortable: true, thStyle: {width: '50px'} },
                { key: 'division', label: 'Подразделение', sortable: true, thStyle: { width: '130px' } },
                { key: 'from_address.name', label: 'Откуда', sortable: true, thStyle: {width: '220px'} },
                { key: 'to_addresses', label: 'Куда', sortable: true, thStyle: {width: '220px'} },
                { key: 'price', label: 'Цена', sortable: true, thStyle: {width: '30px'} },
                { key: 'phone', label: 'Номер телефона', sortable: true, thStyle: {width: '100px'} },
                { key: 'tariff', label: 'Тариф', sortable: true, thStyle: {width: '80px'} },
                { key: 'created_at', label: 'Время поступления', sortable: true, thStyle: {width: '80px'} },
                { key: 'commission_price', label: 'Комиссия', sortable: true, thStyle: {width: '30px'} },
            ],
        }
    },
    methods: {
        getOrderProcessing() {
            this.$http
            .get(`car-state/${this.orderProcessing}/orders`)
            .then(res => {
                this.orderProcessingArray = res.data
                resizeable()
            }).catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Ошибка!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    }
                })
            })
        },
        clicked(item, index, event){
            this.order_id = item.id
            this.$store.state.filterPlus.tableIndex = index
            const clickedElement = event.target;
            const clickedCell = clickedElement.closest('td');
        
            if (!clickedCell) {
                return;
            }
        
            const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);
        
            if (columnIndex < 0 || columnIndex >= this.fields.length) {
                return;
            }
        
            const clickedField = this.fields[columnIndex];
            this.$store.state.filterPlus.keyFilter = clickedField.key;
            this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
            let allTd = document.querySelectorAll('td')
            allTd.forEach(el => {
                el.style.backgroundColor = ''
                el.style.color = ''
            })
            let td = event.target
            if (event.target.tagName.toLowerCase() === 'td') {
                td.style.backgroundColor = '#2979ff'
                td.style.color = '#fff'
            } else if(event.target.tagName.toLowerCase() === 'span'){
                let span = event.target
                span.parentNode.style.backgroundColor = '#2979ff'
                span.parentNode.style.color = '#fff'
            }
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status_id === 10) return 'table-danger'
            else if (item.status_id === 11) return 'table-warning'
            else if (item.status_id === 9) return 'table-success'
            else if (item.status_id === 1) return ''
            else return 'table-secondary'
        },
        getClear() {
            this.submitButtonDisabled = false,
            this.orderProcessingArray = [],
            this.number = null,
            this.$bvModal.hide('orderProcessing')
        },
        getFilingTimeEdit() {
            this.submitButtonDisabled = true
            this.$http.post(`car-state/${this.orderProcessing}/assigment-order?order_id=${this.order_id}&filing_time=${this.filing_time}`)
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Время подачи задано!',
                        icon: 'CheckIcon',
                        variant: 'success'
                    },
                }),
                this.$emit('refresh')
                this.getClear()
            })
            .catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные время подачи задан!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors, 
                    },
                })
            })
            .finally(() => {
                this.submitButtonDisabled = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-context.scss';
table {
  outline: none;
  table-layout: fixed;
  user-select: none;
  overflow: hidden;
  position: relative;
}
tr, td, th {
  padding: 0;
}
.block1{
  overflow: scroll;
}
.parent_block{
  overflow: hidden;
}
td, th {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  box-sizing: border-box;
  min-width: 60px;
}
.inputform {
    margin-top: 10px;
    font-size: 15px;
    margin-right: 5px;
}
</style>