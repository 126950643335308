<template>
    <div>
        <b-modal 
        id="carStateChanges" 
        hide-footer no-enforce-focus 
        :no-close-on-backdrop="true"
        @show="getPerformerChanges()"
        @close="getClear()"
        :title="`${idCarStateCurrent}:${editPerformer.full_name}(${text})`"
        >
        <b-row class="d-flex justify-content-center flex-column">
            <b-col class="pt-1 m-1 border-color">
                <p>{{ `Автомобиль:  ${ editPerformer.transport }` }}</p>  
            </b-col>
            <b-col class="m-1 border-color">
                <label>Действия</label>
                <b-form-checkbox v-model="status" value=1 unchecked-value=0 switch class="custom-control-primary my-1">
                    Закончить смену
                </b-form-checkbox>
            </b-col>
            <b-col class="m-1 border-color">
                <label>Cтатус "Не назначать"</label>
                <b-form-checkbox v-model="is_on_shift" value=1 unchecked-value=0 switch class="custom-control-primary my-1">
                    Закончить смену
                </b-form-checkbox>
            </b-col>
        </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                        :disabled="submitButtonDisabled"
                        @click="getPerformerEdit()"
                        class='mt-2 col-md-5'
                        variant="primary">
                            Ок
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="getClear()">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        ToastificationContent
    },
    props: ['idCarStateCurrent'],
    data() {
        return {
            text: null,
            editPerformer: [],
            status: '0',
            is_on_shift: '0',
            submitButtonDisabled: false
        }
    },
    methods: {
        getPerformerEdit() {
            this.submitButtonDisabled = true
            this.$http.post(`car-state/${this.idCarStateCurrent}/update-performer?status=${this.status}&is_on_shift=${this.is_on_shift}`)
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Статус изменен!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                });
                this.$emit('refresh')
                this.getClear()
            })
            .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Некорректные статус!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: err.response.data.errors, 
                        },
                    })
                })
                .finally(() => {
                    this.submitButtonDisabled = false
                })
            },
        getPerformerChanges() {
            this.$http
            .get(`car-state/${this.idCarStateCurrent}/edit-performer`)
            .then(res => {
                this.editPerformer = res.data
                this.getStatus()
            }).catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Ошибка!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    }
                });
           });
        },
        getStatus() {
            this.text = this.editPerformer.status === 1 ? 'Свободен' : 'Занять';
        },
        getClear() {
            this.$bvModal.hide('carStateChanges'),
            this.editPerformer = [],
            this.text = null,
            this.submitButtonDisabled = false,
            this.status = '0',
            this.is_on_shift = '0'
        }
    }
}
</script>

<style scoped>

.border-color {
    border: 1px solid #ccc;
    border-radius: 5px;
    width: auto;
    background-color: #ccc;
    color: black;
}
</style>
